


















































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";
import countriesList from "@/assets/data/countries.json";
import prefixesList from "@/assets/data/prefixes.json";
import useBase64 from "@/use/base64";
import useSelectItems from "@/use/selectItems";
import { checkVAT, countries } from "jsvat";
import { v4 as uuid } from "uuid";

const { toBase64 } = useBase64();

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
    eventDegrees: {
      type: Array,
      required: false,
      default: () => [],
    },
    eventType: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(_, { root, emit }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      participantPhoto: "",
      oldImage: null as null | { id: string },
      empty: false,
      table: false,
      loaded: false,
      degrees: [],
      eventDegrees: [],
      specialization: [],
      event: {},
      groups: [],
      currency: "",
      otherEmail: false,
      eventType: "",
      primaryConsents: [],
      secondaryConsents: [],
      consents: [],
      registrationFeeTerm: {},
      website: "",
    });

    const model = reactive<{
      data: any;
    }>({
      data: {
        type: "",
        firstName: "",
        lastName: "",
        gender: "",
        languageVersion: "",
        status: "",
        degree: "",
        specialization: "",
        licenseNumber: "",
        studentNumber: "",
        description: "",
        note: "",
        notePriority: "",
        sponsor: "",
        phoneNumber: "",
        email: "",
        country: "",
        addressCity: "",
        addressPostalCode: "",
        addressStreet: "",
        institutionName: "",
        institutionPosition: "",
        institutionCity: "",
        institutionPostalCode: "",
        institutionStreet: "",
        electronicInvoiceConsent: false,
        dateOfInvoice: "",
        invoiceCorrection: "",
        invoiceCorrectionDate: "",
        invoice: {
          invoicePurchaser: "none",
          invoiceName: "",
          invoiceStreet: "",
          invoicePostalCode: "",
          invoiceCity: "",
          invoiceVatNumber: "",
          invoiceConsent: false,
        },
        nfcTag: "",
      },
    });

    //  Start fetch single participant data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${root.$route.params.ptid}`)
        .then(({ data: { participant } }) => {
          model.data.type = participant.type;
          model.data.firstName = participant.firstName;
          model.data.lastName = participant.lastName;
          model.data.gender = participant.gender
            ? participant.gender
            : participant.additionalFields?.salutation || "";
          model.data.languageVersion = participant.languageVersion || "";
          model.data.status = participant.state || "";
          model.data.degree = participant.degree || "";
          model.data.specialization = participant.additionalFields
            ?.specialization
            ? [participant.additionalFields.specialization]
            : participant.specializations
            ? participant.specializations.map((spec: any) => spec.id)
            : "";
          model.data.licenseNumber = participant.licenseNumber || "";
          model.data.studentNumber = participant.studentNumber || "";
          model.data.description = participant.description || "";
          model.data.note = participant.note || "";
          model.data.notePriority = participant.notePriority || "";
          model.data.sponsor = participant.sponsor || "";
          model.data.phonePrefix = participant.phonePrefix || "";
          model.data.phoneNumber = participant.phoneNumber || "";
          model.data.email = participant.email;
          model.data.country = participant.additionalFields?.addressCountry
            ? participant.additionalFields?.addressCountry
            : participant.country;
          model.data.addressCity = participant.addressCity || "";
          model.data.addressPostalCode = participant.addressPostalCode || "";
          model.data.addressStreet = participant.addressStreet || "";
          model.data.institutionName = participant.institutionName || "";
          model.data.institutionPosition =
            participant.institutionPosition || "";
          model.data.institutionCity = participant.institutionCity || "";
          model.data.institutionPostalCode =
            participant.institutionPostalCode || "";
          model.data.institutionPostalCode =
            participant.institutionPostalCode || "";
          model.data.invoice = {
            invoicePurchaser: participant.invoicePurchaser || "none",
            invoiceName: participant.invoiceName || "",
            invoiceStreet: participant.invoiceStreet || "",
            invoicePostalCode: participant.invoicePostalCode || "",
            invoiceCity: participant.invoiceCity || "",
            invoiceEmail: participant.invoiceEmail || "",
            invoiceVatNumber: participant.invoiceVatNumber || "",
            invoiceNumber: participant.invoiceNumber || "",
            invoiceConsent: participant.invoiceConsent || false,
            dateOfInvoice: participant.dateOfInvoice || "",
            invoiceCorrection: participant.invoiceCorrection || "",
            invoiceCorrectionDate: participant.invoiceCorrectionDate || "",
          };
          model.data.electronicInvoiceConsent =
            participant.electronicInvoiceConsent || false;

          model.data.group = participant.participantGroups
            ? participant.participantGroups.map((g: any) => g.id)
            : undefined;

          state.oldImage = participant.image || undefined;

          state.otherEmail = participant.invoiceEmail ? true : false;

          model.data.nfcTag = participant.nfcTag || null;
          model.data.marketingConsent = participant.marketingConsent;
          model.data.emailOffersConsent = participant.emailOffersConsent;
          model.data.phoneOffersConsent = participant.phoneOffersConsent;
          model.data.termsConsent = participant.termsConsent;
          model.data.companion = participant.companion || null;
          state.registrationFeeTerm =
            participant.participantRegistrationFeeTerm;
          model.data.clone = participant.clone;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single participant data

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventDegrees = event.degrees ?? [];
          state.eventType = event.type;
          state.primaryConsents = event.registrationFormTemplate?.groups
            .filter((i: any) => i.type === "consents")
            .map((el: any) => el.fields)
            .flat();
          state.secondaryConsents = event.secondaryRegistrationFormTemplate?.groups
            .filter((i: any) => i.type === "consents")
            .map((el: any) => el.fields)
            .flat();
          state.consents =
            model.data.languageVersion === "polish"
              ? state.primaryConsents
              : state.secondaryConsents;
          state.website = event.wordpressWebsite.domain;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const getConsents = () => {
      if (model.data.languageVersion === "polish") {
        state.consents = state.primaryConsents;
      } else state.consents = state.secondaryConsents;
    };

    watch(() => model.data.languageVersion, getConsents);

    // Start fetch degree items list

    const fetchDegree = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("degree")
        .then(({ data: { degrees } }) => {
          state.empty = false;
          const result = degrees.map((degree: any, index: number) => ({
            id: index,
            name: "",
            ...degree,
          }));
          state.degrees = result.reduce(
            (a: any, o: any) => (a.push(o.name), a),
            []
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.degrees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDegree);

    // End fetch degree items list

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      vatNumber: [
        (v: string) =>
          !v ||
          checkVAT(v, countries).isValid ||
          root.$tc("layout.misc.validVatNumber"),
      ],
      nipNumber: [
        (v: string) =>
          !v ||
          (v.length >= 8 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validNipNumber"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };

    watch(
      () => model.data.image,
      async (newPhoto) => {
        state.participantPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.specialization = specializations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.specialization = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSpecialization);

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.groups = groups.map((degree: any, index: number) => ({
            id: index,
            ...degree,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = state.oldImage?.id;

      const formData = new FormData();

      formData.append("image", model.data.image);

      if (model.data.image) {
        await axiosInstance
          .post("image/participant", formData, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        type: model.data.type,
        firstName: model.data.firstName,
        lastName: model.data.lastName,
        languageVersion: model.data.languageVersion || undefined,
        state: model.data.status || undefined,
        participantGroupIds: model.data.group || [],
        gender: model.data.gender || undefined,
        degree: model.data.degree || null,
        specializations: model.data.specialization || [],
        description: model.data.description || null,
        licenseNumber: model.data.licenseNumber || null,
        studentNumber: model.data.studentNumber || null,
        note: model.data.note || null,
        notePriority: model.data.notePriority || null,
        sponsor: model.data.sponsor || null,
        marketingConsent: model.data.marketingConsent,
        emailOffersConsent: model.data.emailOffersConsent,
        phoneOffersConsent: model.data.phoneOffersConsent,
        termsConsent: model.data.termsConsent,
        phonePrefix: model.data.phonePrefix || null,
        phoneNumber: model.data.phoneNumber.replace(/ /g, "") || null,
        email: model.data.email,
        addressCity: model.data.addressCity || null,
        country: model.data.country || null,
        addressPostalCode: model.data.addressPostalCode || null,
        addressStreet: model.data.addressStreet || null,
        institutionName: model.data.institutionName || null,
        institutionPosition: model.data.institutionPosition || null,
        institutionCity: model.data.institutionCity || null,
        institutionPostalCode: model.data.institutionPostalCode || null,
        institutionStreet: model.data.institutionStreet || null,
        image: imageId || undefined,
        invoicePurchaser: model.data.invoice.invoicePurchaser || null,
        invoiceName: model.data.invoice.invoiceName || null,
        invoiceStreet: model.data.invoice.invoiceStreet || null,
        invoicePostalCode: model.data.invoice.invoicePostalCode || null,
        invoiceCity: model.data.invoice.invoiceCity || null,
        invoiceEmail: model.data.invoice.invoiceEmail || null,
        invoiceVatNumber: model.data.invoice.invoiceVatNumber || null,
        invoiceNumber: model.data.invoice.invoiceNumber || null,
        dateOfInvoice: model.data.invoice.dateOfInvoice || null,
        invoiceCorrection: model.data.invoice.invoiceCorrection || null,
        invoiceCorrectionDate: model.data.invoice.invoiceCorrectionDate || null,
        invoiceConsent:
          model.data.invoice.invoicePurchaser === "individual"
            ? model.data.invoice.invoiceConsent
            : false,
        electronicInvoiceConsent: model.data.electronicInvoiceConsent || false,
        nfcTag: model.data.nfcTag || null,
        companion: model.data.companion || null,
        clone: model.data.clone,
      };

      state.loading = true;

      axiosInstance
        .put(`participant/${root.$route.params.ptid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.participant.editInfo.participantUpdateSuccessfull"
            ),
          });
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const noteItems = ["niski", "wysoki"];

    const {
      participantStatusItems,
      languageItemsCurrency,
      salutationItems,
    } = useSelectItems({ root });

    const replaceVariables = (str: string) => {
      const variables: { [key: string]: string } = {
        "{website}": state.website ? `https://${state.website}` : "",
      };
      for (const variable of Object.keys(variables)) {
        str = str?.replaceAll(variable, variables[variable]) || "";
      }
      return str;
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      rules,
      countriesList,
      prefixesList,
      noteItems,
      participantStatusItems,
      languageItemsCurrency,
      salutationItems,
      replaceVariables,
    };
  },
});
